@import "../../../styles/base/abstracts";

.hbs-accordion {
  @include mq($bp-tablet) {
    @include grid-parent($offset: true);
  }

  .hbs-accordion-item__title {
    @include body-text($ff-sans, $fw-semibold);
  }
}

.hbs-accordion__inner {
  @include mq($bp-tablet) {
    @include grid-child;
  }
}

// .hbs-accordion

.hbs-accordion-item--disabled {
  .hbs-accordion-item__title,
  .hbs-accordion-item__subtitle {
    color: $c-text-lighter;
  }

  button {
    cursor: default;
  }
}

// Trigger
.hbs-accordion-item__trigger {
  @include color-link;
  @include padding($spacing-sm x);
  align-items: center;
  border-top: $border;
  display: flex;
  justify-content: space-between;
  transition: border-color $transition;
  width: 100%;

  &[data-state="open"] {
    border-top: $border-solid;
  }

  @include hover {
    border-top: $border-solid;
  }

  @include mq($bp-desktop) {
    @include padding($spacing-md x);
  }
}

.hbs-accordion-item__header {
  display: flex;
  flex-direction: column;
}

.hbs-accordion-item__subtitle {
  padding-bottom: $spacing-xxs;
}

.hbs-accordion-item__icon {
  @include size($icon-size-sm);
  stroke: $c-spot;
  min-width: 13px;

  @media (prefers-reduced-motion: no-preference) {
    path {
      transform-origin: center;
      transition:
        transform $t-time-md $t-ease-out,
        opacity $transition;
    }
  }

  [data-state="open"] & {
    path {
      transform: rotateZ(-90deg);
    }

    path:first-child {
      opacity: 0;
    }
  }
}

// Content
// margin/padding offset to fix focus indictor clipping
.hbs-accordion-item__content {
  @include baseline-text;
  overflow: hidden;
  padding-top: $spacing-xs;
  margin-top: -$spacing-xs;
  padding-left: $spacing-xs;
  margin-left: -$spacing-xs;
  max-width: 45em;

  // Lists
  ul {
    @include ul;

    ul {
      @include margin(0 x);
    }
  }

  ol {
    @include ol;

    li {
      @include margin(x x x 1.4em);
    }
  }

  p {
    color: $c-text-light;
    margin-bottom: $spacing-md;
  }

  h2,
  h3,
  h4,
  h5,
  h6 {
    color: $c-text-light;
    @include baseline-text;
    margin-bottom: $spacing-md;
  }

  @media (prefers-reduced-motion: no-preference) {
    &[data-state="open"] {
      animation: open $transition-sm forwards;
    }

    &[data-state="closed"] {
      animation: close $transition-sm forwards;
    }
  }
}

.hbs-accordion-item__ctas {
  display: flex;
  flex-direction: column;
  padding-bottom: $spacing-md;

  .hbs-cta-link {
    margin-bottom: $spacing-sm;
  }

  @include mq($bp-tablet) {
    display: inherit;
    padding-bottom: $spacing-xl;

    .hbs-cta-link {
      margin-right: $spacing-sm;
    }
  }
}
